<template>
  <div>
    <filter-slot
      :filter="customFilter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="$refs['refClientsList'].refresh()"
      @onSelectChange="changeTypePayment"
    >
      <template #buttons v-if="moduleId != 4">
        <!-- Button Send Email -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="lengthClients"
          @click="openModalSendEmail"
        >
          <feather-icon icon="MailIcon" class="mr-50" />Send Email
        </b-button>
        <!-- Button refresh -->
        <b-button
          variant="success"
          class="ml-1"
          :disabled="pnttime"
          @click="contador"
        >
          <feather-icon icon="RefreshCcwIcon" class="mr-50" />{{
            pnttime == false
              ? "Refresh"
              : tiempominutos + ":" + tiemposegundos + " sec"
          }}
        </b-button>
        <div class="ml-3">
          <span class="text-danger mr-2 font-weight-bolder"
            >Red schedule: not available</span
          ><br />
          <span class="text-success mr-2 font-weight-bolder"
            >Green schedule: available</span
          ><br />
          <span class="text-primary font-weight-bolder"
            >Blue schedule: will be available
          </span>
        </div>

        <b-button
          v-if="(isAdvisor || isSupervisor) && moduleId == 5"
          variant="info"
          @click="openModalQuestionnaire"
          class="ml-1"
        >
          <span>Questionnaire</span>
          <tabler-icon
            v-if="countQuestionnaire > 0"
            class="ml-50"
            :badge="countQuestionnaire > 99 ? '+99' : countQuestionnaire"
            badge-classes="bg-danger ml-2"
            icon="BookIcon"
          />
          <tabler-icon v-else class="ml-50" icon="BookIcon" />
        </b-button>
      </template>
      <template #custom-vselect>
        <FilterStatusAccount :filters="customFilter[3]" />
      </template>
      <b-table
        slot="table"
        ref="refClientsList"
        small
        no-provider-filtering
        :api-url="apiUrl"
        :items="myProvider"
        :fields="fieldsStatus"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- Selected Title -->
        <template #head(selected)>
          <b-form-checkbox v-model="selectAll" @input="selectedAll" />
        </template>
        <!-- Selected -->
        <template v-slot:cell(selected)="data" v-if="moduleId != 4">
          <b-form-group>
            <b-form-checkbox
              v-model="clientsSms"
              :value="{
                id: JSON.parse(data.item.accounts)[0].account_id,
                name: data.item.lead_name,
              }"
              :name="JSON.parse(data.item.accounts)[0].account_id"
              class="mt-1"
            />
          </b-form-group>
        </template>
        <!-- Column NAME -->
        <template #cell(lead_name)="data">
          <div v-if="moduleId == 4" class="cursor-pointer text-primary">
            <a
              class="cursor-pointer"
              @click="openEditLead(data.item.lead_id)"
              >{{ data.item.lead_name }}</a
            >
          </div>
          <template v-else>
            <router-link
              class
              :to="{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: JSON.parse(data.item.accounts)[0].account_id,
                },
              }"
              target="_blank"
              >{{ data.item.lead_name }}</router-link
            >
            <b-badge
              v-if="JSON.parse(data.item.accounts)[0].new_and_not_welcomed == 1"
              variant="light-warning"
              class="ml-1"
              >W/o Welcome</b-badge
            >
          </template>
          <div>
            <span
              style="font-weight: 100"
              v-if="isDigital != 2 && moduleId != 4"
              >{{ data.item.mobile }}
            </span>
          </div>
          <b-badge v-if="data.item.approved_by_management" variant="info"
            >Approved by Management</b-badge
          >
          <b-badge
            v-if="data.item.claims != 0"
            class="mr-1"
            variant="light-info"
          >
            IN CLAIM ({{ data.item.claims }})
          </b-badge>
          <b-badge
            v-if="data.item.has_court != 0"
            class="mr-1"
            variant="light-success"
          >
            IN COURT ({{ data.item.has_court }})
          </b-badge>
        </template>
        <!-- column CONTACT SCHEDULE -->
        <template #cell(contact_schedule)="data">
          <div
            v-if="data.item.always_available == 1"
            class="d-flex justify-content-center align-items-center mt-1 font-weight-bolder"
            style="color: #00cc00 !important"
          >
            <feather-icon
              icon="CalendarIcon"
              size="15"
              class="mr-1"
            ></feather-icon>
            Always available
          </div>
          <div v-else-if="data.item.contact_schedule">
            <div
              class="text-left"
              style="font-weight: 100"
              v-for="(schedule, index) in JSON.parse(
                data.item.contact_schedule
              ).slice(0, 3)"
              :key="index"
            >
              <b-row :class="getColor(schedule)">
                <b-col cols="1">
                  <feather-icon
                    v-if="[1, 2].includes(schedule.priority)"
                    icon="AlertTriangleIcon"
                    size="12"
                    style="margin-left: 5px"
                    v-b-tooltip.hover
                    :title="
                      { 1: 'High Priority', 2: 'Medium Priority' }[
                        schedule.priority
                      ]
                    "
                    :class="{
                      'text-danger': schedule.priority == 1,
                      'text-warning': schedule.priority == 2,
                    }"
                  ></feather-icon>
                </b-col>
                <b-col cols="4">
                  <feather-icon icon="CalendarIcon" size="12"></feather-icon>
                  <small>
                    {{ schedule.date }}
                  </small>
                </b-col>
                <b-col cols="6">
                  <feather-icon
                    icon="ClockIcon"
                    size="12"
                    style="margin-right: 3px"
                  ></feather-icon>
                  <small for="" v-if="schedule.time_to">
                    {{ schedule.time_from | myTime }} -
                    {{ schedule.time_to | myTime }}</small
                  >
                  <small for="" v-else
                    >From: {{ schedule.time_from | myTime }}</small
                  >
                </b-col>
              </b-row>
              <hr
                v-if="index + 1 < JSON.parse(data.item.contact_schedule).length"
                style="margin: 0px !important"
              />
            </div>
            <b-button
              v-if="JSON.parse(data.item.contact_schedule).length > 3"
              variant="info"
              size="sm"
              @click="showFullContactSchedule(data.item.contact_schedule)"
            >
              +
              {{
                JSON.parse(data.item.contact_schedule).length > 3
                  ? JSON.parse(data.item.contact_schedule).length - 3
                  : ""
              }}
            </b-button>
          </div>
        </template>
        <!-- Column ACCOUNT -->
        <template #cell(account)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                <router-link
                  v-if="moduleId == 4"
                  :to="{
                    name: 'administration-dashboard',
                    params: {
                      idClient: account.account_id,
                    },
                  }"
                  target="_blank"
                >
                  {{ account.account }}</router-link
                >
                <span v-else style="font-weight: 100">{{
                  account.account
                }}</span>
              </li>
            </ul>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                <status-account v-if="moduleId != 4" :account="account">
                </status-account>
              </li>
            </ul>
          </div>
        </template>
        <!-- Column PROGRAM -->
        <template v-if="moduleId == 2 || moduleId == 4" #cell(program)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                {{ account.program }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column Change Service -->
        <template v-if="moduleId == 4" #cell(change_service)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <template
                v-for="(account, index) in JSON.parse(data.item.accounts)"
              >
                <li
                  :key="index"
                  style="list-style: none"
                  v-if="account.type_program == 2"
                >
                  <b-badge
                    pill
                    variant="primary"
                    style="font-size: 12px !important"
                  >
                    YES
                  </b-badge>
                </li>
              </template>
            </ul>
          </div>
        </template>

        <!-- Status Column -->
        <template v-if="moduleId == 4" #cell(status)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none; position: relative"
              >
                <status-account
                  :account="account"
                  :text="true"
                ></status-account>
              </li>
            </ul>
          </div>
        </template>

        <!-- Fee & Charges Column -->
        <template v-if="moduleId == 4" #cell(charges)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                {{ account.charges ? "$ " + account.charges : " " }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column AVAILABLE BALANCE -->
        <template
          v-if="
            (moduleId != 2 && moduleId != 6 && moduleId != 4) ||
            (moduleId == 6 && currentUser.role_id != 4 && moduleId != 4)
          "
          #cell(available_balance)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
                :class="
                  account.total_balance > 0 ? 'text-primary' : 'text-danger'
                "
              >
                $ {{ account.total_balance | currencyZero }}
              </li>
            </ul>
          </div>
        </template>

        <!-- Column Total Debt -->
        <template
          v-if="
            (moduleId != 2 && moduleId != 6 && moduleId != 4) ||
            (moduleId == 6 && currentUser.role_id != 4 && moduleId != 4)
          "
          #cell(total_debt)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                {{ account.total_accounts | myMoney }}
              </li>
            </ul>
          </div>
        </template>
        <!-- Column MP -->
        <template
          v-if="
            (moduleId != 2 && moduleId != 6) ||
            (moduleId == 6 && currentUser.role_id != 4)
          "
          #cell(monthly_amount)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                {{ account.monthly_amount | myMoney }}
              </li>
            </ul>
          </div>
        </template>
        <!-- Column LP -->
        <template
          v-if="
            (moduleId != 2 && moduleId != 6) ||
            (moduleId == 6 && currentUser.role_id != 4)
          "
          #cell(last_payment)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none; position: relative"
              >
                <span style="margin-left: 15px">
                  {{ account.last_payment == "-" ? "" : account.last_payment }}
                  <img
                    :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                    v-if="account.state_lp == 1"
                    style="
                      position: absolute;
                      left: 0;
                      top: 50%;
                      transform: translate(0, -50%);
                    "
                  />
                  <img
                    :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                    v-else
                    style="
                      position: absolute;
                      left: 0;
                      top: 50%;
                      transform: translate(0, -50%);
                    "
                  />
                </span>
              </li>
            </ul>
          </div>
        </template>
        <!-- Column PT -->
        <template
          v-if="
            (moduleId != 2 && moduleId != 6) ||
            (moduleId == 6 && currentUser.role_id != 4)
          "
          #cell(status_payment)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                <status-payment :account="account" />
              </li>
            </ul>
          </div>
        </template>
        <!-- Column ADVISOR -->
        <template
          v-if="currentUser.role_id != 3 || moduleId == 2 || moduleId == 5"
          #cell(state_advisor)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
              >
                <div>
                  <feather-icon
                    id="icon"
                    :title="
                      account.state_advisor == 1
                        ? 'Active'
                        : account.state_advisor == 2
                        ? 'Busy'
                        : account.state_advisor == 3
                        ? 'Away'
                        : 'Offline'
                    "
                    icon="CircleIcon"
                    size="13"
                    :style="
                      account.state_advisor == 1
                        ? 'background:green'
                        : account.state_advisor == 2
                        ? 'background:orange'
                        : account.state_advisor == 3
                        ? 'background:red'
                        : 'background:#ccc'
                    "
                  />
                  {{ account.advisor_name }}
                </div>
              </li>
            </ul>
          </div>
        </template>
        <!-- Column LN -->
        <template
          v-if="
            (moduleId != 2 && moduleId != 4 && moduleId != 6) ||
            (moduleId == 6 && currentUser.role_id != 4)
          "
          #cell(s_note)="data"
        >
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px; list-style: none">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                class="li-created-at-account"
                :class="
                  account.s_note <= 1
                    ? ''
                    : account.s_note <= 2
                    ? 'text-warning'
                    : 'text-danger'
                "
              >
                {{ account.last_note }}
              </li>
            </ul>
          </div>
        </template>
        <!-- Column CREATION DATE -->
        <template #cell(created_at)="data">
          <div>
            <ul style="padding-left: 0px; margin-bottom: 0px">
              <li
                v-for="(account, index) in JSON.parse(data.item.accounts)"
                :key="index"
                style="list-style: none"
                class="li-created-at-account"
              >
                {{ account.created_at | myGlobal }}
              </li>
            </ul>
          </div>
        </template>
        <!-- Column SERVICES -->
        <template
          v-if="[1, 2, 3].includes(currentUser.role_id) && moduleId != 4"
          #cell(service)="data"
        >
          <actions-table
            :options="actionsOptions"
            :row-data="data.item"
            @onRowDelete="onRowDelete"
            @onRowEdit="onRowEdit"
            @onRowProcess="onRowProcess"
            @modalChangeService="modalChangeService"
            @modalAddService="modalAddService"
          />
        </template>
      </b-table>
    </filter-slot>

    <lead-update
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      v-if="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />

    <!-- Modal send sms -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Send Email -->
    <modal-send-email
      v-if="modalSendEmail"
      :modalSendEmail="modalSendEmail"
      :nameLeads="clientsSms"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />
    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />

    <!--modal view contact schedule-->
    <b-modal
      v-model="showModalSchedule"
      title="Full Contact schedule"
      title-tag="h3"
      modal-class="modal-primary"
      title-class="h3 text-white font-weight-bolder"
      scrollable
      size="default"
    >
      <b-card no-body class="mb-0">
        <div
          v-for="(date, index) in contactScheduleList"
          :key="index"
          class="list-group-item bg-transparent rounded-0"
          :class="isDarkSkin ? 'border-light' : ''"
        >
          <b-row
            :class="
              date.date == currentDate.day && date.time_to < currentDate.hour
                ? 'past'
                : (date.date == customFilter[7].model ||
                    date.date == currentDate.day) &&
                  ((date.time_from <= customFilter[8].model &&
                    date.time_to >= customFilter[9].model) ||
                    (date.time_from <= currentDate.hour &&
                      date.time_to >= currentDate.hour) ||
                    !date.time_to)
                ? 'now'
                : date.date == currentDate.day
                ? 'future'
                : ''
            "
          >
            <b-col cols="1">
              <feather-icon
                v-if="date.priority != 3"
                icon="AlertTriangleIcon"
                size="17"
                v-b-tooltip.hover
                :title="
                  date.priority == 1 ? 'High Priority' : 'Medium Priority'
                "
                class="ml-1"
                :class="date.priority == 1 ? 'text-danger' : 'text-warning'"
              ></feather-icon>
            </b-col>
            <b-col cols="6">
              <span for="" class="">
                <feather-icon icon="CalendarIcon"></feather-icon>
                {{ date.date }}
              </span>
            </b-col>
            <b-col cols="5">
              <feather-icon icon="ClockIcon"></feather-icon>
              <span v-if="date.time_to">
                {{ date.time_from | myTime }} -
                {{ date.time_to | myTime }}
              </span>
              <span v-else> From {{ date.time_from | myTime }} </span>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="(showModalSchedule = false), (contactScheduleList = [])"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <QuestionnaireModal v-if="G_S_VALIDATE_OLD_QUESTIONNAIRE" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
// Filters
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue";
// Componenrts
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import ModalAddService from "../modals/ModalAddService.vue";
import ModalSendEmail from "../modals/ModalSendEmail.vue";
import ModalSendSms from "../modals/ModalSendSms.vue";
import StatusPayment from "../stylescomponents/StatusPayment.vue";
import clientsFilters from "../data/filters.clients.data";
import administrationFilters from "../data/filters.administration.data";
import ClientService from "../services/clients.services";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import DebtSolutionServices from "@/views/debt-solution/services/debt-solution.service.js";
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import moment from "moment";

import QuestionnaireModal from "@/views/commons/components/modals-container/ModalsContainer.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
export default {
  props: {
    isDigital: {
      type: Number,
    },
  },
  components: {
    QuestionnaireModal,
    FilterSlot,
    StatusAccount,
    StatusPayment,
    ModalSendSms,
    ModalSendEmail,
    ModalAddService,
    NcrLeadsService,
    ActionsTable,
    BusinessModal,
    CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    LeadUpdate,
    FilterStatusAccount,
  },
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      typeEdit: "lead",
      customFilter: [],
      typeAddChange: null,
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      modalOpenAddService: false,
      typeModal: null,
      clients: null,
      clientsSms: [],
      selectAll: 0, // total
      allClients: [], // lista de listas
      arrayCurrentPage: [],
      fields: [],
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      totalAmount: 0,
      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      tiemposegundos: 30,
      tiempominutos: 0,
      time1: true,
      pnttime: false,
      advisor: "",
      modalSendSms: false,
      modalSendEmail: false,
      actionsOptions: [],
      detailsClient: {},
      saleClientP: {},
      programsAll: [],
      usersServices: [],
      event: null,
      showModalSchedule: false,
      contactScheduleList: [],
      countQuestionnaire: 0,
    };
  },
  computed: {
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
    }),

    ...mapGetters({
      currentUser: "auth/currentUser",
      G_EEUU_STATES: "CrmGlobalStore/G_EEUU_STATES",
      G_S_VALIDATE_OLD_QUESTIONNAIRE:
        "modal-actions/G_S_VALIDATE_OLD_QUESTIONNAIRE",
    }),
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },
    apiUrl() {
      if (this.moduleId == 4) {
        return "/clients/search-clients";
      } else {
        return "/clients/clientsds";
      }
    },
    fieldsStatus() {
      this.fields = [
        this.moduleId != 4
          ? {
              key: "selected",
              label: "",
              sortable: false,
            }
          : null,
        {
          key: "lead_name",
          label: "Name",
          sortable: true,
          visible: true,
        },
        {
          key: "lead_state",
          label: "State",
          sortable: false,
          visible: true,
        },
        {
          key: "contact_schedule",
          label: "Contact Schedule",
          sortable: false,
          visible: true,
          class: "text-center",
          thStyle: {
            size: "200px",
          },
        },
        this.moduleId == 4
          ? {
              key: "mobile",
              label: "Mobile",
              sortable: true,
              visible: true,
            }
          : null,
        {
          key: "account",
          label: "Account",
          sortable: true,
          visible: true,
        },
        this.moduleId == 2 || this.moduleId == 4
          ? {
              key: "program",
              label: "Program",
              sortable: true,
              visible: true,
            }
          : null,
        this.moduleId == 4
          ? {
              key: "change_service",
              label: "Change Service",
              sortable: true,
              visible: true,
            }
          : null,
        this.moduleId == 4
          ? {
              key: "status",
              label: "Status",
              sortable: true,
              visible: true,
            }
          : null,
        this.moduleId == 4
          ? {
              key: "charges",
              label: "Fee & Charges",
              sortable: true,
              visible: true,
            }
          : null,

        {
          key: "account",
          label: "Account",
          sortable: true,
          visible: true,
        },
        (this.moduleId != 2 && this.moduleId != 6 && this.moduleId != 4) ||
        (this.moduleId == 6 &&
          this.currentUser.role_id != 4 &&
          this.moduleId != 4)
          ? {
              key: "available_balance",
              label: "Available Balance",
              sortable: true,
              visible: true,
            }
          : null,
        this.moduleId != 4
          ? {
              key: "total_debt",
              label: "Total Debt",
              visible: true,
            }
          : null,

        (this.moduleId != 2 && this.moduleId != 6) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4)
          ? {
              key: "monthly_amount",
              label: "MP",
              sortable: true,
              visible: true,
            }
          : null,
        (this.moduleId != 2 && this.moduleId != 6) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4)
          ? {
              key: "last_payment",
              label: "LP",
              sortable: true,
              visible: true,
            }
          : null,
        (this.moduleId != 2 && this.moduleId != 6) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4)
          ? {
              key: "status_payment",
              label: "PT",
              sortable: true,
              visible: true,
            }
          : null,
        this.currentUser.role_id != 3 ||
        this.moduleId == 2 ||
        this.moduleId == 5
          ? {
              key: "state_advisor",
              label: "Advisor",
              visible: true,
            }
          : null,
        (this.moduleId != 2 && this.moduleId != 4 && this.moduleId != 6) ||
        (this.moduleId == 6 && this.currentUser.role_id != 4)
          ? {
              key: "s_note",
              label: "LN",
              sortable: true,
              visible: true,
            }
          : null,
        {
          key: "created_at",
          label: "Creation Date",
          sortable: true,
          visible: true,
        },
        // validate here
        (this.currentUser.role_id == 1 && this.moduleId != 4) ||
        (this.currentUser.role_id == 2 && this.moduleId != 4) ||
        (this.currentUser.role_id == 3 && this.moduleId != 4)
          ? {
              key: "service",
              label: "Services",
              visible: true,
            }
          : null,
      ];

      if (this.moduleId == 4) {
        this.fields.forEach((field) => {
          if (field) {
            field.sortable = false;
          }
        });
      }
      return this.fields;
    },
    lengthClients() {
      return this.clientsSms.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    currentDate() {
      return {
        day: moment().format("dddd").toUpperCase(),
        hour: moment().format("HH:mm:ss"),
      };
    },
  },
  async mounted() {
    if (this.moduleId != 4) {
      this.actionsOptions.push("addService");
      this.actionsOptions.push("changeService");
      await this.getEeuuStates();
      this.customFilter[6].options = this.G_EEUU_STATES;
    }
  },
  methods: {
    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      getOldQuestionnaire: "modal-actions/A_VALIDATE_OLD_QUESTIONNAIRE",
    }),
    getColor({ date, time_from, time_to }) {
      const filterDate = this.customFilter[7].model
        ? this.customFilter[7].model
        : this.currentDate.day;
      const filterTimeFrom = this.customFilter[8].model
        ? this.customFilter[8].model
        : this.currentDate.hour;
      const filterTimeTo = this.customFilter[9].model
        ? this.customFilter[9].model
        : this.currentDate.hour;

      return date == filterDate && time_to < filterTimeTo
        ? "past"
        : date == filterDate &&
          time_from <= filterTimeFrom &&
          (time_to >= filterTimeTo || !time_to)
        ? "now"
        : date == filterDate
        ? "future"
        : "";
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getLeadEdit(leadId) {
      try {
        await this.A_GET_LEAD_EDIT({ id: leadId });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },

    async openEditLead(leadId) {
      this.isAddUpdateUserSidebarActive = false;
      this.addPreloader();
      await this.getLeadEdit(leadId);
      if (this.editSidebar === "first") {
        await Promise.all([
          this.getPrograms(),
          this.getStateLeads(),
          this.getStatusLeads(),
          this.getSourceLeads(),
          this.getSourceNames(),
          this.getStates(),
          this.getEeuuStates(),
          this.getCountries(),
          this.getOwners(),
        ]);
        this.editSidebar = "second";
      }

      this.S_LEAD_EDIT.address = {
        id: this.S_LEAD_EDIT.id,
        prename: "main",
        streetReal: this.S_LEAD_EDIT.street,
        street: this.S_LEAD_EDIT.street,
        city: this.S_LEAD_EDIT.city,
        state: this.S_LEAD_EDIT.states_eeuu_slug,
        zipcode: this.S_LEAD_EDIT.zipcode,
        country: this.S_LEAD_EDIT.country,
      };
      this.isAddUpdateUserSidebarActive = true;
      this.removePreloader();
    },
    // async refresh() {
    //   this.addPreloader();
    //   const data = await this.$store.dispatch(
    //     "DebtSolutionClients/A_GET_CLIENTS",
    //     {
    //       id: this.$route.params.idClient,
    //     }
    //   );
    //   this.removePreloader();
    // },

    async refresh() {
      this.addPreloader();
      // if (this.moduleId == 4) {
      this.$refs["refClientsList"].refresh();
      this.isAddUpdateUserSidebarActive = false;
      // }
      // const data = await this.$store.dispatch(
      //   "DebtSolutionClients/A_GET_CLIENTS",
      //   {
      //     id: this.S_LEAD_EDIT.id,
      //     // id: this.$route.params.idClient,
      //   }
      // );
      this.removePreloader();
    },

    // openModalSendSms() {
    //   this.modalSendSms = true;
    // },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    inactiveFilter() {
      if (this.currentUser.role_id == 3 && this.moduleId != 5) {
        this.customFilter[2].visible = false;
      }
    },
    changeTypePayment(type) {
      if (type == "paymentType") {
        if (this.customFilter[4].model == 1) {
          this.customFilter[5].visible = true;
        } else {
          this.customFilter[5].model = null;
          this.customFilter[5].visible = false;
        }
      }
    },
    async contador() {
      try {
        this.pnttime = true;
        this.updateReloj();
        // setTimeout(() => {
        //   this.pnttime = false;
        // }, 1000 * this.tiemposegundos + 60000 * this.tiempominutos);
        await ClientService.generateRefresh();
      } catch (error) {
        console.log(error);
      }
    },
    updateReloj() {
      this.tiemposegundos = this.tiemposegundos;
      this.tiempominutos = this.tiempominutos;
      if (this.tiempominutos == 0 && this.tiemposegundos == 0) {
        this.$refs.refClientsList.refresh();
        this.pnttime = false;
        this.tiemposegundos = 59;
        this.tiempominutos = 1;
      } else {
        // Restamos un segundo al tiempo restante
        if (this.tiemposegundos == 0) {
          this.tiemposegundos = 59;
          if (this.tiempominutos != 0) {
            this.tiempominutos -= 1;
          }
        }
        this.tiemposegundos -= 1;
        // Ejecutamos nuevamente la función al pasar 1000 milisegundos (1 segundo)
        setTimeout(() => {
          this.updateReloj();
        }, 1000);
      }
    },
    selectedAll() {
      if (this.selectAll == 1) {
        let nameCl = [];
        this.clients.map((dato) => {
          nameCl.push({
            id: JSON.parse(dato.accounts)[0].account_id,
            name: dato.lead_name,
          });
        });
        let value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (!value) {
          this.allClients.push({
            currentPage: this.paginate.currentPage,
            array: nameCl,
          });
        }
      }
      if (this.selectAll == 0) {
        const value = this.allClients.find(
          (element) => element.currentPage == this.paginate.currentPage
        );
        if (value) {
          const index = this.allClients.indexOf(value);
          this.allClients.splice(index, 1);
        }
      }
      this.clientsSms = [];
      for (let i = 0; i < this.allClients.length; i++) {
        // let obj = this.allClients[i].array
        // let reduceId = obj.map((data)=> data.id)
        this.clientsSms = [...this.clientsSms, ...this.allClients[i].array];
      }
    },
    async myProvider(ctx) {
      //create a new array with the name of columns and the index of the column to sort by
      const sortOptions = {
        lead_name: 2,
        account: 5,
        available_balance: 50,
        monthly_amount: 43,
        last_payment: 44,
        status_payment: 48,
        s_note: 45,
        created_at: 5,
      };
      //get the index of the column to sort by
      let sortBy = sortOptions[ctx.sortBy];
      let sortDirection = ctx.sortDesc ? "desc" : "asc";

      try {
        let module = 0;
        switch (parseInt(this.moduleId)) {
          case 2:
            module = this.program;
            break;
          case 4:
            module = this.program;
            break;
          case 3:
            module = 1;
            break;
          case 7:
            module = 2;
            break;
          case 6:
            module = 3;
            break;
          case 5:
            module = 4;
            break;
          case 8:
            module = 5;
            break;
          case 10:
            module = 6;
            break;
        }
        if (parseInt(this.moduleId) != 2) {
          if (
            this.currentUser.role_id == 1 ||
            this.currentUser.role_id == 2 ||
            this.moduleId == 5
          ) {
            this.advisor = this.customFilter[2].model;
          } else {
            this.advisor = this.currentUser.user_id;
          }
        } else {
          this.advisor = this.customFilter[2].model;
        }

        let params = {};
        if (this.moduleId == 4) {
          params = {
            program: this.customFilter[2].model,
            hasChange: this.customFilter[6].model,
          };
        } else {
          params = {
            program: module,
            advisor: this.advisor,
          };
        }

        if (parseInt(this.moduleId) == 4) {
          params["is_digital"] = this.isDigital;
        }

        params["perPage"] = this.paginate.perPage;
        params["text"] = this.filterPrincipal.model;
        params["from"] = this.customFilter[0].model;
        params["to"] = this.customFilter[1].model;
        params["orderby"] = sortBy;
        params["order"] = sortDirection;
        params["status"] = this.customFilter[3].model;
        params["type"] = this.customFilter[4].model;
        params["day"] = this.customFilter[5]?.model;
        params["state"] = this.customFilter[6]?.model;
        params["rol_id"] = this.currentUser.role_id;
        params["schedule_date_from"] = this.customFilter[7]?.model;
        params["schedule_time_from"] = this.customFilter[8]?.model;
        params["schedule_time_to"] = this.customFilter[9]?.model;
        if (this.moduleId == 4) {
          params["id_user"] = this.currentUser.user_id;
        }
        const { data } = await amgApi.post(
          `${ctx.apiUrl}?page=${ctx.currentPage}`,
          params
        );
        const items = data.data;
        this.clients = items;
        this.startPage = data.from || 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page || 0;
        this.totalRows = data.total || 0;
        this.toPage = data.to || 0;
        return items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.moduleId == 5 ? 1 : this.currentUser.role_id,
        };
        if (this.currentUser.role_id != 3 || this.moduleId == 5) {
          const data = await ClientService.usersPrograms(params);
          this.customFilter[2].label = "Advisor";
          this.customFilter[2].selectText = "user_name";
          this.customFilter[2].options = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    searchPrograms: async function (lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          let programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort(function (a, b) {
            return a.program_id - b.program_id;
          });
          for (var i = 0; i < this.programsAll.length; i++) {
            for (var y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          var sper = this.currentUser.sale_permission;
          var spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    usersAllPrograms: async function () {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data; //.filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    onRowDelete(id) {},
    onRowEdit: async function (data) {},
    modalChangeService: async function (data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },
    onRowProcess(id) {},
    modalAddService: async function (data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );

      if (result.value) {
        this.typeAddChange = 1;
        this.modalGeneralService(data, 3);
        await this.createEvent(data);
      }
    },

    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    // Create the event
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    // Select the program
    async openModalProgram(data) {
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          // await this.createLeadEvent();
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: JSON.parse(this.detailsClient.accounts)[0].account_id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            let result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: "sales-made-debtsolution-client-pending" })
                .catch((err) => {
                  console.log(err);
                });
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    showFullContactSchedule(contactSchedule) {
      this.contactScheduleList = JSON.parse(contactSchedule);
      this.showModalSchedule = true;
    },
    async openModalQuestionnaire() {
      this.getOldQuestionnaire({
        advisor_id: !this.isSupervisor ? this.currentUser.user_id : null,
      });
    },
    async getCountNotificationQuestionnaire() {
      try {
        const { data } =
          await DebtSolutionServices.notificationOldQquestionnaire({
            advisor_id: this.currentUser.user_id,
          });
        this.countQuestionnaire = data.length;
      } catch (error) {
        console.log(error);
      }
    },
    // customFilter
    async getAllStatusClientAccount() {
      this.customFilter[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.customFilter[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.customFilter[3].options.push(...data);
    },
  },
  created() {
    if (this.moduleId == 4) {
      this.customFilter = administrationFilters;
    } else {
      if (this.isAdvisor) {
        this.getCountNotificationQuestionnaire();
      }
      this.customFilter = clientsFilters;
      this.usersPrograms();
      this.inactiveFilter();
      this.usersAllPrograms();
    }
    this.getAllStatusClientAccount();
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.now {
  color: #00cc00 !important;
  font-weight: bold !important;
  animation: grow_up 0.8s ease-in-out 3;
}
.past {
  color: #fc424a !important;
  font-weight: bold !important;
}
.future {
  color: #0090e7 !important;
  font-weight: bold !important;
}
.now small,
.past small,
.future small {
  font-weight: bold !important;
  color: currentColor;
}
@keyframes grow_up {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
